import TimeAgo from "javascript-time-ago";
import { Flex, Text } from "@radix-ui/themes";
import { formatNumber } from "@/lib/util";
import { IconCalendarMonth, IconHeart, IconMessage, IconPlayerPlay, IconReload } from "@tabler/icons-react";
import Image from "next/image";
import { memo } from "react";


interface Props {
    fullPosts: any;
    postsRange: [number, number];
}

const timeAgo = new TimeAgo("en-US");
const formatter = new Intl.NumberFormat("en-US");
const PostsRow = ({
    fullPosts,
    postsRange,
}: Props) => {
  const posts = fullPosts.sort((a: any, b: any) => b.views - a.views);
  console.log({posts})
  return (
    <Flex direction="row" gap="4">
        {(posts.slice(postsRange[0], postsRange[1])).map(
    (post: any) =>
     ( post.thumbnail || post.thumbnailSmall) && !post.missing && (
        <Flex key={post.id} direction="column" style={{cursor: 'pointer', width: '20%', height: '50vh'}} onClick={() => {
          window.open(post.url, "blank")
        }}>
          <Flex className="img__wrap" justify="end">
            <div >
            <Image className="rounded-xl"  fill  alt="" src={`https://wvemedia.com/image-upload/${post?.thumbnail || post?.thumbnailSmall}`} style={{width: '100%', height: '100%', objectFit: "cover"}}/>
            </div>
            <Flex className="img__default_layer">
              <Flex className="img__default">
                <Flex direction="column" justify="end" align="start" gap="1"  p="4" style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  width: '100%',
                }}>
                  <Flex direction="row" justify="between" align="end" gap="2">
                    {post.views && (
                      <Flex direction="row" gap="1" justify="start" align="center"> 
                        <IconPlayerPlay className="icon-accent" width="18px"/>
                        <Text size="2">{formatNumber(post.views)}</Text>
                      </Flex>
                    )}
                    {post.likes && (
                      <Flex direction="row" gap="1" justify="start" align="center"> 
                        <IconHeart className="icon-accent" width="18px"/>
                        <Text size="2">{formatNumber(post.likes)}</Text>
                      </Flex>
                    )}
                    {post.comments && (
                      <Flex direction="row" gap="1" justify="start" align="center"> 
                        <IconMessage className="icon-accent" width="18px"/>
                        <Text size="2">{formatNumber(post.comments)}</Text>
                      </Flex>
                    )}
                  </Flex>
                {post.username && (
                    <Text className="font-semibold">@{post.username}</Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex className="img__description_layer rounded-xl" direction="column" justify="end">
              <Flex className="img__description">
                <Flex direction="column" gap="2" align="start" justify="end" p="4">
                  {post.views && (
                    <Flex direction="row" gap="1" justify="start" align="center"> 
                      <IconPlayerPlay className="icon-accent" width="20px"/>
                      <Text size="2">{formatter.format(post.views)} Views</Text>
                    </Flex>
                  )}
                  {post.likes && (
                    <Flex direction="row" gap="1" justify="start" align="center"> 
                      <IconHeart className="icon-accent" width="20px"/>
                      <Text size="2">{formatter.format(post.likes)} Likes</Text>
                    </Flex>
                  )}
                  {post.comments && (
                    <Flex direction="row" gap="1" justify="start" align="center"> 
                      <IconMessage className="icon-accent" width="20px"/>
                      <Text size="2">{formatter.format(post.comments)} Comments</Text>
                    </Flex>
                  )}
                  <Flex direction="row" gap="1" justify="start" align="center">
                    <IconCalendarMonth className="icon-accent" width="20px"/>
                    <Text size="2">
                      Posted{" "}
                      {new Date(post.postedAt)?.toLocaleDateString("en-US", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </Text>
                  </Flex>
                  {post.lastScraped && (
                    <Flex direction="row" gap="1" justify="start" align="center"> 
                      <IconReload className="icon-accent" width="20px"/>
                      <Text size="2"> Updated {timeAgo.format(new Date(post.lastScraped))}</Text>
                    </Flex>
                    )}
  
                  {post.username && (
                    <Text className="font-semibold">@{post.username}</Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )
  )}     
    </Flex>
  );
};

export default memo(PostsRow);
