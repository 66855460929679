import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useUser from "@/lib/useUser";
import { Box, Flex, Heading, Text, Switch, Select } from "@radix-ui/themes";
import CampaignSearch from "@/components/NewCampaignViews/CampaignSearch";
import { updateStatus, useCampaign } from "@/services/campaign";
import useSWR from "swr";
import fetchJson from "@/lib/fetchJson";
import DashboardView from "@/components/NewCampaignViews/DashboardView";
import { IconUserCircle } from "@tabler/icons-react";
import AdvancedView from "./Advanced/AdvancedView";
import Image from "next/image";

interface DashboardProps {
  id: string;
  setId: (id: string) => void;
  home: boolean;
}

const NewDashboard = ({ id, setId, home }: DashboardProps) => {
  const router = useRouter();
  useEffect(() => {
    setId(router.query.id as any);
  }, [router.query.id]);
  const { user, checkRoles } = useUser();
  const { data, mutate } = useCampaign(id);
  const { data: ugc } = useSWR(`/campaign/${id}/ugc`, fetchJson);
  const { data: streams } = useSWR(`/campaign/${id}/streams`, fetchJson);

  const findView = (home) => {
    if (home) {
      return "dashboard"
    } else {
      return user.campaignView
    }
  } 
  const [selectedView, setSelectedView] = useState(findView(home))
  if (!user) return null;
  const d =
    checkRoles(["agency-admin", "agency-user"]) &&
    user.agencyId == data?.agencyId;
  const editable = checkRoles(["superadmin"]) || d;
  const shareable =
    checkRoles(["superadmin"]) ||
    (checkRoles(["agency-admin"]) && data?.agencyId == user.agencyId) ||
    (checkRoles(["agency-user"]) && data?.agencyId == user.agencyId) ||
    checkRoles(["campaign-viewer"]);
  const transferable = checkRoles(["superadmin"])
  const isAdmin = checkRoles(["agency-admin", "superadmin"])
  let reportable =
    d ||
    (user?.viewableCampaigns?.includes(parseInt(id)) &&
      checkRoles(["campaign-viewer"]));
  if (user?.email === "nick@pulsemusicgroup.com") reportable = false;

  if (!id) return null;

  const updateCampaignStatus = async (status: string) => {
    await updateStatus(data.id, status);
    mutate();
  };

  return (
      <Box id="dashboard-bg" width="100%" minHeight="100%">
        <Flex direction="column" gap="4" pb="5">
          <Flex id="campaing-info" height="130px" gap="3" direction="row" justify="between" align="center">
            <Flex gap="3" direction="row">
              <Box id="campaign-image">
                {data?.song?.image && (
                  <Image
                    src={`https://wvemedia.com/image-upload/${data.song?.image || data.artist?.image}`}
                    width={120}
                    height={120}
                    alt=""
                    className="w-[120px] h-[120px] rounded-2xl"
                  />
                )}
              </Box>
              <Flex direction="column" justify="center" py="1" gap="2">
                <Box id="campaign-name">{data && <Heading size="8">{data?.name}</Heading>}</Box>
                <Flex direction="row" gap="4" align="center">
                  {data && <Flex id="artist-name" direction="row" gap="1" align="center">
                    <IconUserCircle className="icon-dark" />
                    <Text size="2">{data?.artist?.name}</Text>
                  </Flex>}
                  <Box id="campaign-status">
                    {data && selectedView=="advanced" ? <Select.Root disabled={!editable} size="1" defaultValue={data?.status === 'active' ? 'active' : data?.status === 'draft' ? 'draft' : 'archived'}

                      onValueChange={newStatus => {
                        updateCampaignStatus(newStatus)
                      }}
                    >
                      <Select.Trigger />
                      <Select.Content>
                        <Select.Item value="active"><Flex direction="row" align="center">
                          <div className="status-dot-active"></div>
                          <Text size="2">Active</Text>
                        </Flex></Select.Item>
                        <Select.Item value="archived"><Flex direction="row" align="center">
                          <div className="status-dot-inactive"></div>
                          <Text size="2">Archived</Text>
                        </Flex></Select.Item>
                        <Select.Item value="draft"><Flex direction="row" align="center">
                          <div className="status-dot-draft"></div>
                          <Text size="2">Draft</Text>
                        </Flex></Select.Item>
                      </Select.Content>
                    </Select.Root> :
                    <>
                    {data?.status == "active" ? 
                    <Flex direction="row" align="center">
                    <div className="status-dot-active"></div>
                    <Text size="2">Active</Text>
                  </Flex>:
                  <Flex direction="row" align="center">
                  <div className="status-dot-inactive"></div>
                  <Text size="2">Archived</Text>
                </Flex>
                  }
                  </>}

                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <Flex id="campaign-search" direction="row" gap="2" justify="center" align="center">
              <CampaignSearch />
              {isAdmin && <Flex direction="row" gap="2" align="center">
                <Switch checked={selectedView == "advanced"} onCheckedChange={() => {
                  setSelectedView(selectedView === "dashboard" ? "advanced" : "dashboard");
                }} /> 
                <Flex direction="column">
                  <Text size="2">Advanced</Text>
                  <Text size="2">View</Text>
                </Flex>
              </Flex>}
            </Flex>
          </Flex>
        </Flex>
        <div>
          <div className="hidden lg:block">
            {selectedView === "dashboard" ? <DashboardView id={id} setId={setId} /> :
              <AdvancedView
                editable={editable}
                shareable={shareable}
                reportable={reportable}
                data={data}
                mutate={mutate}
                transferable={transferable} />
            }
          </div>
        </div>
      </Box>
    )
};

export default NewDashboard;