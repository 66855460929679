import { Flex, Text } from "@radix-ui/themes";
import { IconCalendarMonth, IconChevronDown } from "@tabler/icons-react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useEffect, useRef, useState } from "react";

export default function DateFilter(props) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    return (<div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block', alignContent: "center" }}>
        <Flex direction="row" gap="2" align="center" onClick={() => setIsOpen(true)} >
            <Text size="2">Date Range</Text>
            <Flex direction="row" align="end">
                <IconChevronDown height="18" className="icon" style={{strokeWidth: 2}} />
            </Flex>
        </Flex>
        {isOpen && <Flex
            style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                marginTop: '10px',
                padding: '10px',
                backgroundColor: 'var(--gray-2)',
                zIndex: 1,
                width: 360
            }}
        ><DateRangePicker
                onChange={props.setFilterDates}
                value={props.filterDates}
                calendarIcon={<IconCalendarMonth className="icon" />}
            />
        </Flex>}
    </div>)
}