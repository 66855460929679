import { Badge, Button, Flex, Heading, IconButton, Table, Text, TextField, Tooltip } from "@radix-ui/themes";
import Link from "next/link";
import { IconAlertTriangle, IconArrowsSort, IconChevronLeft, IconChevronRight, IconSearch, IconSortAscending, IconSortDescending, IconTrash } from "@tabler/icons-react";
import { KeyedMutator } from "swr";
import { deletePost } from "@/services/post";
import NewEditPost from "../../Campaign/modals/NewEditPost";
import Image from "next/image";
import { useEffect, useMemo, useRef, useState } from "react";
import NewMassImport from "@/components/Campaign/modals/NewMassImport";

interface PostsTabProps {
    fullPosts: Post[];
    mutate: KeyedMutator<Campaign>;
    id: any;
    editable: boolean;
}
const headerToKey = {
    "Post Date": "postedAt",
    "Author": "username",
    "Views": "views",
    "Likes": "likes",
    "Shares": "shares",
    "Comments": "comments"
}

const PostsTab = ({ fullPosts, mutate, editable, id }: PostsTabProps) => {
    const [page, setPage] = useState(0)
    const PAGE_SIZE = 10
    const headers = ["Post", "Link", "Tags", "Author", "Post Date", "Likes", "Shares", "Views", "Comments"]
    const [sortKey, setSortKey] = useState("Views")
    const [sortDir, setSortDir] = useState(1)


    const [posts, setPosts] = useState([...fullPosts].sort((a: any, b: any) => {
        const key = headerToKey[sortKey]
        if (key === "postedAt") {
            return (new Date(b[key]).getTime() - new Date(a[key]).getTime()) * sortDir
        }
        if (key === "username") {
            if (sortDir === 1) {
                return b[key]?.localeCompare(a[key])
            } else {
                return a[key]?.localeCompare(b[key])
            }
        }
        return (b[key] - a[key]) * sortDir
    }))
    const tableRef = useRef(null);

   

    const removePost = async (id: number) => {
        if (confirm("Are you sure you want to delete this post?")) {
            await deletePost(id);
            mutate();
        }
    };
    const [search, setSearch] = useState("");
    const searched = posts?.filter((i: any) =>
        i.username?.toLowerCase().includes(search.toLowerCase()) || i.url?.toLowerCase().includes(search.toLowerCase()),
    );
    useEffect(() => {
        setPosts([...fullPosts].sort((a: any, b: any) => {
            const key = headerToKey[sortKey]
            if (key === "postedAt") {
                return (new Date(b[key]).getTime() - new Date(a[key]).getTime()) * sortDir
            }
            if (key === "username") {
                if (sortDir === 1) {
                    return b[key]?.localeCompare(a[key])
                } else {
                    return a[key]?.localeCompare(b[key])
                }
            }
            return (b[key] - a[key]) * sortDir
        }))
    }, [fullPosts])

    useEffect(() => {
        setPage(0);
        setPosts([...posts].sort((a: any, b: any) => {
            const key = headerToKey[sortKey]
            if (key === "postedAt") {
                return (new Date(b[key]).getTime() - new Date(a[key]).getTime()) * sortDir
            }
            if (key === "username") {
                if (sortDir === 1) {
                    return b[key]?.localeCompare(a[key])
                } else {
                    return a[key]?.localeCompare(b[key])
                }
            }
            return (b[key] - a[key]) * sortDir
        }))
    }, [sortKey, sortDir])



    useEffect(() => {
        setPage(0);
    }, [search]);

    console.log("tableRef.current", tableRef.current?.offsetWidth)
    const tableWidth = useMemo(() => {
        return tableRef.current?.offsetWidth
    }, [tableRef.current])

    return (
        <Flex className="box" direction="column" gap="5" p="5" ref={tableRef}>
            <TextField.Root
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search posts..."
                radius="medium"
                variant="soft"
            >
                <TextField.Slot pr="4">
                    <IconSearch className="icon-dark" width="20px" />
                </TextField.Slot>
            </TextField.Root>
            <Flex direction="row" justify="between">
                <Heading size="4">Posts</Heading>
                {editable && <NewMassImport type="post" mutate={mutate} campaignId={id} />}
            </Flex>
            {posts?.length ? <Table.Root size="1" style={{ width: "100%" }} >
                <Table.Header>
                    <Table.Row>
                        {headers.map((item: any) => (
                            <Table.ColumnHeaderCell

                                style={{ cursor: headerToKey[item] === undefined ? "auto" : "pointer" }}
                                onClick={() => {
                                    if (headerToKey[item] === undefined) {
                                        return
                                    }
                                    if (item === sortKey) {
                                        setSortDir(prev => prev * -1)
                                    } else {
                                        setSortKey(item)
                                        setSortDir(1)
                                    }
                                }}
                                key={item}>
                                <Flex direction="row" align="center" gap="1">

                                    {item}
                                    {item === sortKey &&
                                        (sortDir === 1 ? <IconSortDescending size="20px" className="icon"
                                            style={{
                                                color: item === sortKey ? "var(--gray-10)" : "var(--gray-7)"
                                            }}
                                        /> : <IconSortAscending size="20px" className="icon"
                                            style={{
                                                color: item === sortKey ? "var(--gray-10)" : "var(--gray-7)"
                                            }}
                                        />)}
                                </Flex>
                            </Table.ColumnHeaderCell>
                        ))}
                        {editable && <Table.ColumnHeaderCell key={"Actions"}>{"Actions"}</Table.ColumnHeaderCell>}
                    </Table.Row>
                </Table.Header>

                <Table.Body style={{ width: "100%" }} >
                    {tableWidth && searched && searched.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((post: Post) => (
                        <Table.Row align="center" key={post.id} style={{ width: "100%" }}>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.05 : "5%" }}> 
                            <Flex direction="row" align="center" gap={"1"}>
                                <Link href={post.url} target="_blank">
                                    <div className="small-thumbnail" style={{ position: "relative", cursor: "pointer" }}>
                                        {post.thumbnailSmall ? <Image
                                            fill
                                            className="small-thumbnail"
                                            src={`https://wvemedia.com/image-upload/${post.thumbnailSmall}`}
                                            alt=""
                                        /> :
                                            post.thumbnail ? <Image
                                                fill
                                                className="small-thumbnail"
                                                src={`https://wvemedia.com/image-upload/${post.thumbnail}`}
                                                alt=""
                                            /> : <Image
                                                fill
                                                className="small-thumbnail"
                                                src="/PostImage.png"
                                                alt=""
                                            />}
                                    </div>
                                </Link>
                                {post?.missing && <Tooltip content={"Post is missing!"}>
                                    <div>
                                        <IconAlertTriangle className="icon-accent" size="20" />
                                    </div>
                                </Tooltip>}
                            </Flex>
                            </Table.Cell>
                            <Table.Cell style={{ minWidth: tableWidth ? tableWidth * 0.25 : "25%", maxWidth: tableRef.current?.offsetWidth * 0.25 || "25%" }}>
                                <Link href={post.url} target="_blank">
                                    {post.url}
                                </Link>
                            </Table.Cell>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.10 : "10%", minWidth: tableWidth ? tableWidth * 0.10 : "10%", maxWidth: tableWidth ? tableWidth * 0.10 : "10%" }}>{post.tags && post.tags.map((tag: string) => (
                                <Badge key={tag} style={{marginRight: 3, marginTop: 3}} >{tag}</Badge>
                            ))}</Table.Cell>
                            <Table.Cell style={{ minWidth: tableWidth ? tableWidth * 0.1 : "10%", maxWidth: tableWidth ? tableWidth * 0.1 : "10%" }}>{post.username && <Text>@{post.username}</Text>}</Table.Cell>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.12 : "12%", minWidth: tableWidth ? tableWidth * 0.12 : "12%", maxWidth: tableWidth ? tableWidth * 0.12 : "12%" }}>{post.postedAt && `${new Date(post.postedAt).toLocaleDateString()} ${new Date(post.postedAt).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}</Table.Cell>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.05 : "5%" }}>{(post.shares && post.views && !post.likes) ? 'Hidden' : post.likes ? post.likes.toLocaleString() : ''}</Table.Cell>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.05 : "5%" }}>{post.shares && post.shares?.toLocaleString()}</Table.Cell>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.05 : "5%" }}>{post.views && post.views?.toLocaleString()}</Table.Cell>
                            <Table.Cell style={{ width: tableWidth ? tableWidth * 0.05 : "5%" }}>{post.comments && post.comments?.toLocaleString()}</Table.Cell>
                            {editable && <Table.Cell style={{ width: tableWidth ? tableWidth * 0.1 : "10%" }}>
                                <Flex direction="row" align="center" gap="2">
                                    <Link href={`/post/${post.id}`}>
                                        <Button variant="outline">History</Button>
                                    </Link>
                                    {post.tags && <NewEditPost mutate={mutate} post={post} />}
                                    <IconButton variant="outline" onClick={() => removePost(post.id)}>
                                        <IconTrash className="icon-accent" />
                                    </IconButton>
                                </Flex>
                            </Table.Cell>}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table.Root> :
                <Text>
                    No posts yet.
                </Text>}
            <Flex direction="row" gap="3" justify="center">
                <Button variant="ghost" disabled={page === 0} onClick={() => setPage((prev: any) => prev - 1)}><IconChevronLeft className="icon-accent" />Previous</Button>
                <Button variant="ghost" disabled={page >= (searched?.length / PAGE_SIZE - 1)} onClick={() => setPage((prev: any) => prev + 1)}>Next<IconChevronRight className="icon-accent" /></Button>
            </Flex>
        </Flex>
    )
}

export default PostsTab;